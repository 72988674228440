import styled from '@emotion/styled';
import { Breakpoint } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { Card } from '../card';

const NEW_PHONE = '@media (min-width: 376px)';
const TABLET = '@media (min-width: 814px)';
const LAPTOP = '@media (min-width: 1112px)';
const FOURK_DESKTOP = '@media (min-width: 2560px)';

export const BlockBackground = styled(Card)(
  {
    padding: '16px 4.8%',

    [NEW_PHONE]: {
      padding: '16px 6.25%',
    },
    [TABLET]: {
      padding: '16px 7.5%',
    },
    [LAPTOP]: {
      padding: '16px 12.5%',
    },
    [Breakpoint.HDScreenOrLarger]: {
      padding: '32px 20%',
    },
    [FOURK_DESKTOP]: {
      padding: '32px 25%',
    },
  },
  () => {
    if ($buildEnv.theme === 'momentum') {
      return {
        backgroundColor: 'unset',
      };
    }
    if ($buildEnv.theme === 'momentum-neon') {
      return {
        backgroundColor: 'unset',
      };
    }

    return null;
  },
);
