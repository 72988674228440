import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { PrimaryBrandHeading as PrimaryHeading_quirky_purple } from './Heading.quirky-purple';
import { PrimaryBrandHeading as PrimaryHeading_classic } from './Heading.classic';
import { PrimaryBrandHeading as PrimaryHeading_momentum } from './Heading.momentum';
import { PrimaryBrandHeading as PrimaryHeading_golden } from './Heading.golden';
import { PrimaryBrandHeading as PrimaryHeading_quirky } from './Heading.quirky';
import { PrimaryBrandHeading as PrimaryHeading_clean } from './Heading.clean';
import { PrimaryBrandHeading as PrimaryHeading_dreams } from './Heading.dreams';
import { PrimaryHeading } from './Heading.default';

export const HeadingStyles = (() => {
  if ($buildEnv.theme === 'classic') {
    return [PrimaryHeading, PrimaryHeading_classic];
  }
  if ($buildEnv.theme === 'clean') {
    return [PrimaryHeading, PrimaryHeading_clean];
  }
  if ($buildEnv.theme === 'clean-blue') {
    return [PrimaryHeading, PrimaryHeading_clean];
  }
  if ($buildEnv.theme === 'golden') {
    return [PrimaryHeading, PrimaryHeading_golden];
  }
  if ($buildEnv.theme === 'goldenV2') {
    return [PrimaryHeading, PrimaryHeading_golden];
  }
  if ($buildEnv.theme === 'quirky') {
    return [PrimaryHeading, PrimaryHeading_quirky];
  }
  if ($buildEnv.theme === 'quirky-purple') {
    return [PrimaryHeading, PrimaryHeading_quirky_purple];
  }
  if ($buildEnv.theme === 'dreams') {
    return [PrimaryHeading, PrimaryHeading_dreams];
  }
  if ($buildEnv.theme === 'momentum') {
    return [PrimaryHeading, PrimaryHeading_momentum];
  }
  if ($buildEnv.theme === 'momentum-neon') {
    return [PrimaryHeading, PrimaryHeading_momentum];
  }

  return PrimaryHeading;
})();
