import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Breakpoint, Color, FontTextSize } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';

const StyledTabs = styled.div({
  '--tab-font-size': FontTextSize.Normal,
  '--tab-border-width': '2px',
  '--tab-outline-width': '2px',

  gap: 8,
  display: 'inline-flex',
  flexWrap: 'wrap',
  justifyContent: 'center',

  // This can not be a direct tab selector,
  // as sometimes need wrap the tabs in a div.
  '[role="tab"]': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',
    padding: '0.4em 1.2em',

    color: Color.TextColors.BodyText,
    fontFamily: 'inherit',
    fontSize: 'var(--tab-font-size)',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    textTransform: $buildEnv.theme === 'classic-red' ? 'uppercase' : undefined,

    border: 'var(--tab-border-width) solid currentColor',
    borderRadius: 'var(--button-border-radius)',

    cursor: 'pointer',
    background: 'transparent',
    outline: 'transparent',

    // Spacing inside tabs.
    // Since iOS has issues with flex/grid on buttons we use margin instead.
    '& > *': {
      marginLeft: 'calc(1.2em / 2)',
    },

    ':focus-visible': {
      '::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        inset: 'calc(var(--tab-border-width) * -1 - 2px)',
        boxShadow: `0 0 0 var(--tab-outline-width) ${Color.Primitive.Accent}`,
        borderRadius: `calc(var(--button-border-radius) + calc((var(--tab-border-width) + var(--tab-outline-width)) / 2))`,
      },
    },

    '&[aria-selected="true"]': {
      color: Color.Primitive.PrimaryContrast,
      background: Color.Primitive.Primary,
      borderColor: 'transparent',
    },

    '@media (any-hover: hover)': {
      ':hover:not([aria-selected="true"])': {
        color: Color.Primitive.PrimaryContrast,
        background: Color.Primitive.Primary,
        borderColor: 'transparent',
      },
    },
  },

  // Modifiers that alters the default styles.
  '&[data-size="small"]': {
    '--tab-font-size': FontTextSize.Small,
    '--tab-border-width': '1px',
  },
});

export const ScrollableTabs: FC<PropsWithChildren> = styled.div({
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },

  display: 'flex',
  justifyContent: 'center',

  paddingBlock: '8px 16px',
  paddingInline: '8px',
  marginInline: '-8px',

  'main > &': {
    paddingInline: 0,
    marginInline: 0,

    [Breakpoint.Phone]: {
      gridColumn: '1 / -1',
      '[role="tablist"]': {
        paddingLeft: 'var(--full-width-margin)',
        paddingRight: 'var(--full-width-margin)',
      },
    },
  },

  [Breakpoint.Phone]: {
    // It's important that this is within this breakpoint,
    // as dropdowns that are rendered by the tabs will be cut off otherwise.
    overflowX: 'scroll',
    justifyContent: 'flex-start',

    [`> ${StyledTabs}`]: {
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
    },
  },
});

type TabsProps = {
  size?: 'default' | 'small';
  children: ReactNode;
};

export const Tabs: FC<TabsProps> = ({ size, children }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const { current } = ref;
    if (current == null) {
      return;
    }

    // This should be a safe selection, since it's very similar to the CSS one.
    const tabs = Array.from(current.querySelectorAll<Element>(':scope [role=tab]'));

    // Only the first tab should be initially focusable.
    tabs.forEach((tab, index) => {
      tab.setAttribute('tabindex', index === 0 ? '0' : '-1');
    });

    const selectTab = (node: Element) => {
      if (node instanceof HTMLElement) {
        tabs.forEach((tab) => {
          tab.setAttribute('tabindex', '-1');
        });
        node.setAttribute('tabindex', '0');
        node.focus();
      }
    };

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.target instanceof HTMLElement) {
        const index = tabs.indexOf(event.target);

        if (event.key === 'ArrowRight') {
          event.preventDefault();
          // Select the next tab, and wrap around if last.
          selectTab(tabs[index === tabs.length - 1 ? 0 : index + 1]);
        }

        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          // Select the previous tab, and wrap around if first.
          selectTab(tabs[index === 0 ? tabs.length - 1 : index - 1]);
        }
      }
    };

    current.addEventListener('keydown', onKeyDown);
    return () => {
      current.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  return (
    <StyledTabs role="tablist" data-size={size} ref={ref}>
      {children}
    </StyledTabs>
  );
};
